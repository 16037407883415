import {
    ConfirmationDialog,
    IConfirmationDialogProps,
  } from './ConfirmationDialog';
  import {
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    TextInput,
  } from '@patternfly/react-core';
  import React, { FunctionComponent, useState } from 'react';
  
  export interface IExportJsonMappingDialog {
    isOpen: IConfirmationDialogProps['isOpen'];
    onCancel: IConfirmationDialogProps['onCancel'];
    onConfirm: (filename: string) => void;
  }
  export const ExportJsonMappingDialog: FunctionComponent<
  IExportJsonMappingDialog
  > = ({ isOpen, onCancel, onConfirm }) => {
    const defaultCatalogName = 'atlasmap-mapping';
    const [filename, setFilename] = useState(defaultCatalogName);
    const handleOnConfirm =
      filename.length > 0 ? () => onConfirm(filename) : undefined;
    return (
      <ConfirmationDialog
        title={'Export Mappings.'}
        onCancel={onCancel}
        onConfirm={handleOnConfirm}
        isOpen={isOpen}
        dataTestid={'export-json-dialog'}
      >
        <Form>
          <FormGroup
            label={'Please enter a name for your exported json file'}
            fieldId={'filename'}
            isRequired={true}
          >
            <InputGroup>
              <TextInput
                value={filename}
                onChange={setFilename}
                id={'filename'}
                isRequired={true}
                autoFocus={true}
                data-testid={'export-json-dialog-text-input'}
              />
              <InputGroupText>.json</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Form>
      </ConfirmationDialog>
    );
  };
  