/*
    Copyright (C) 2017 Red Hat, Inc.

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

            http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { ExpressionToolbar, MainLayout } from '../Layout';
import {
  CanvasProvider,
  ConditionalExpressionInput,
  FieldDragLayer,
  FieldsDndProvider,
  TimedToast,
} from '../UI';
import {
  IAtlasmapField,
  IAtlasmapMapping,
  ISourceColumnCallbacks,
  ITargetsColumnCallbacks,
  MappingTableView,
  NamespaceTableView,
  SourceTargetView,
} from '../Views';
import { IUseContextToolbarData, useContextToolbar } from './useContextToolbar';
import { deselectMapping, getConstantType, getPropertyType } from './utils';

import { AlertGroup, Banner, Button, Modal, ModalVariant, Page, PageSection, PageSectionVariants } from '@patternfly/react-core';
import { FLOW_HEADERS } from '../../ApiConfig';
import { fetchEvents } from './AIPService';
import { useAtlasmap } from './AtlasmapProvider';
import { AuditLog } from './AuditLog';
import { fetchTemplate, fetchTemplateFile } from './FlowService';
import { PreviewModal } from './PreviewModal';
import { TemplateSelection } from './TemplateSelection';
import { useAtlasmapDialogs } from './useAtlasmapDialogs';
import { useSidebar } from './useSidebar';
import { LookupConfigModal } from './LookupConfigModal';

export interface IAtlasmapProps {
  allowImport?: boolean;
  allowExport?: boolean;
  allowReset?: boolean;
  allowDelete?: boolean;
  allowCustomJavaClasses?: boolean;
  modalsContainerId?: string;
  toolbarOptions?: IUseContextToolbarData;
  templateData?: any;
}

export const Atlasmap: FunctionComponent<IAtlasmapProps> = ({
  allowImport = true,
  allowExport = true,
  allowReset = true,
  allowDelete = true,
  allowCustomJavaClasses = true,
  modalsContainerId = 'modals',
  toolbarOptions
}) => {
  const {
    pending,
    // error,
    notifications,
    markNotificationRead,
    sourceProperties,
    targetProperties,
    constants,
    sources,
    targets,
    mappings,
    selectedMapping,
    selectMapping,
    onFieldPreviewChange,
    searchSources,
    searchTargets,
    importJarFile,
    // expression
    currentMappingExpression,
    executeFieldSearch,
    getFieldEnums,
    setSelectedEnumValue,
    mappingExpressionAddField,
    mappingExpressionClearText,
    mappingExpressionInit,
    mappingExpressionInsertText,
    mappingExpressionObservable,
    mappingExpressionRemoveField,
    mappingExpressionEnabled,
    isMappingExpressionEmpty,
    trailerId,
    canAddToSelectedMapping,
    isFieldAddableToSelection,
    isFieldDragAndDropAllowed,
    isFieldRemovableFromSelection,
    onAddToMapping,
    onRemoveFromMapping,
    onCreateMapping,
    isEnumerationMapping,
    importADMArchiveFile,
    directResetAtlasmap,
    getCurrentADMArchive,
    documentExists,
  } = useAtlasmap();

  const [showBanner, setShowBanner] = React.useState(false);
  const [showTemplateNotFound, setShowTemplateNotFound] = React.useState(false);
  let search = window.location.search.replace('?data=', ''); 
  let convertedData = search ? atob(search) : '';  
  let query = useRef<any>({});  
  if (convertedData) {  
    let params = new URLSearchParams(decodeURI(convertedData));  
    params.forEach((value, key) => {  
      query.current[key] = value.replace(/"/g, '\\"');  
    });  
  }
  const token = query.current.token;
  const [tenantId] = React.useState<string>(query.current.tenantId);
  const [templateId, setTemplateId] = React.useState<string>('');
  const [showAuditLog, setShowAuditLog] = React.useState(false);
  const [templateExists, setTemplateExists] = React.useState(false);
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const [openLookupConfigModal, setOpenLookupConfigModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const isValid: boolean = query.current.sourceId ? true : false;
  const isTemplateStandard: boolean = query.current.tenantId ? false : true;
  const souceName = query.current.sourceName || '';
  const targetName = query.current.targetName || '';

  const templateName = isValid ? ` ${souceName} - ${targetName}` : ''
  const [templateData, setTemplateData] = React.useState<any>({
    sourceId: query.current.sourceId,
    targetId: query.current.targetId,
    sourceEventType: query.current.sourceEventType,
    targetEventType: query.current.targetEventType,
    sourceName: query.current.sourceName,
    targetName: query.current.targetName,
    customerName: query.current.tenantName,
    isCreate: false,
    isUpdate: true,
    template: '',
    templateName: templateName,
    tenantId: query.current.tenantId,
    sourceVersion: isValid ? 'default' : '',
    targetVersion: isValid ? 'default' : '',
    isValid: isValid,
    templateExists: templateExists,
    mappingType: 'STANDARD',
    lookupConfig: null,
  });

  FLOW_HEADERS['x-aptean-tenant'] = tenantId || '';
  let postSaveProcess = useCallback((response) => {
    console.log(response);
    if (response.success) {
      setTemplateId(response.data.id);
      setTemplateData({ ...templateData, templateId: response.data.id, mappingType: response.data.mappingType, lookupConfig: response.data.lookupConfig});
      setTemplateExists(true);
      setShowBanner(true);
      setTimeout(() => {
        setShowBanner(false);
      }, 3000);
      deselectMapping();
    }
  }, []);
  const handleModalToggle = () => {
    setShowAuditLog(!showAuditLog);
  }
  const handlePreviewModal = () => {
    setOpenPreviewModal(!openPreviewModal);
  }
  const handleLookupConfigModal = () => {
    setOpenLookupConfigModal(!openLookupConfigModal);
  }
  const resetToStandard = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const standardReset = useCallback((restore) => {
    // Set tenantId to empty to get the standard template
    FLOW_HEADERS['x-aptean-tenant'] = '';
    fetchTemplate(`?sourceId=${encodeURIComponent(templateData.sourceId)}&sourceVersion=${encodeURIComponent(templateData.sourceVersion)}&sourceEventType=${encodeURIComponent(templateData.sourceEventType)}&targetId=${encodeURIComponent(templateData.targetId)}&targetVersion=${encodeURIComponent(templateData.targetVersion)}&targetEventType=${encodeURIComponent(templateData.targetEventType)} `,token)
    .then(function (item: any) {
      if (!item.data) {
        console.log(`Template ${templateData.templateName} not found`);
        // Show banner if the template is not found
        setShowTemplateNotFound(true);
        setTimeout(() => {
          setShowTemplateNotFound(false);
        }, 3000);
        
        // Set tenantId back to original value
        FLOW_HEADERS['x-aptean-tenant'] = tenantId;
        return;
      }
    directResetAtlasmap().then(_ => {
          fetchTemplateFile(item.data?.id,templateData.sourceSchema,templateData.targetSchema,token).
            then((blob) => {
              // Set tenantId back to original value
              FLOW_HEADERS['x-aptean-tenant'] = tenantId;
              if(restore){
                item.data.mappingType = 'CUSTOM';
              }
              setTemplateData({ ...templateData, ...item.data, templateExists: true, templateName: item.data?.name,mappingType: item.data.mappingType, lookupConfig: item.data.lookupConfig});
              setTemplateExists(true);
              importADMArchiveFile(new File([blob], templateId));
            }).
            catch((err) => {
              FLOW_HEADERS['x-aptean-tenant'] = tenantId;
              console.error(err);
            });
        }).catch(function (e) {
          FLOW_HEADERS['x-aptean-tenant'] = tenantId;
          console.log(e);
        })
    })
  }, [templateData]);

  useEffect(() => {
    if (templateData.isValid) {
      // Step 1: Fetch source and target events
      const fetchSourceEvents = fetchEvents(templateData.sourceId, token);
      const fetchTargetEvents = fetchEvents(templateData.targetId, token);
  
      // Step 2: Fetch the template and handle subsequent operations
      Promise.all([fetchSourceEvents, fetchTargetEvents, fetchTemplate(`?sourceId=${encodeURIComponent(templateData.sourceId)}&sourceVersion=${encodeURIComponent(templateData.sourceVersion)}&sourceEventType=${encodeURIComponent(templateData.sourceEventType)}&targetId=${encodeURIComponent(templateData.targetId)}&targetVersion=${encodeURIComponent(templateData.targetVersion)}&targetEventType=${encodeURIComponent(templateData.targetEventType)}`, token)])
        .then(([sourceResponse, targetResponse, templateResponse]) => {
          const sourceSchemas = JSON.parse(JSON.stringify(sourceResponse.items));
          const targetSchemas = JSON.parse(JSON.stringify(targetResponse.items));
  
          let filteredSourceSchema = sourceSchemas?.find((x: any) => x.type === query.current.sourceEventType)?.payloadSchema;
          let filteredTargetSchema = targetSchemas?.find((x: any) => x.type === query.current.targetEventType)?.payloadSchema;

          filteredSourceSchema = filteredSourceSchema?.xmlContent ? filteredSourceSchema.xmlContent : JSON.stringify(filteredSourceSchema);
          filteredTargetSchema = filteredTargetSchema?.xmlContent ? filteredTargetSchema.xmlContent : JSON.stringify(filteredTargetSchema);
  
          console.log(filteredSourceSchema);
          console.log(filteredTargetSchema);
  
          if (templateResponse.success) {
            const templateId = templateResponse.data?.id;
            if (templateId) {
              setTemplateId(templateId);
  
              // Step 3: Fetch the template file with the event schemas
              return fetchTemplateFile(templateId, filteredSourceSchema, filteredTargetSchema, token)
                .then((blob) => {
                  setTemplateData({
                    ...templateData,
                    ...templateResponse.data,
                    templateExists: true,
                    templateName: templateResponse.data?.name,
                    mappingType: templateResponse.data.mappingType,
                    lookupConfig: templateResponse.data.lookupConfig,
                    sourceSchemas: sourceSchemas,
                    targetSchemas: targetSchemas
                  });
                  setTemplateExists(true);
                  importADMArchiveFile(new File([blob], templateId));
                  return true; // Success
                })
                .catch((err) => {
                  console.error(err);
                  return false; // Error in fetching template file
                });
            } else {
              console.log('No templateId found');
              return false; // No templateId found
            }
          } else {
            console.log('No templates found');
            return directResetAtlasmap()
              .then(() => {
                setTemplateData({
                  ...templateData,
                  sourceSchemas: sourceSchemas,
                  targetSchemas: targetSchemas
                });
                return false; // Error in template fetch
              })
              .catch((err) => {
                console.error(err);
                return false; // Error in direct reset
              });
          }
        })
        .catch((err) => {
          console.error(err);
          return false; // Error in fetching source or target events or template
        });
    }
  }, [templateData.isValid]);
  

  const { handlers, dialogs } = useAtlasmapDialogs({
    modalContainer: document.getElementById(modalsContainerId)!,
  });
  const { activeView, showMappingPreview, showTypes, contextToolbar } =
    useContextToolbar({
      showImportAtlasFileToolbarItem: allowImport,
      showImportJarFileToolbarItem: allowImport,
      showExportAtlasFileToolbarItem: allowExport,
      showExportMappingJsonToolbarItem: allowExport,
      showResetToolbarItem: allowReset,
      ...toolbarOptions,
      onImportADMArchiveFile: handlers.onImportADMArchive,
      onImportJarFile: (file) => importJarFile(file),
      onExportAtlasFile: handlers.onExportADMArchive,
      onExportMappingJsonFile: handlers.onExportMappingJsonFile,
      onResetAtlasmap: handlers.onResetAtlasmap,
      onAbout: handlers.onAbout,
    });

  const shouldShowMappingPreviewForField = useCallback(
    (field: IAtlasmapField) =>
      showMappingPreview &&
      !!selectedMapping &&
      field.isConnected &&
      !!field.mappings.find((m) => m.id === selectedMapping.id),
    [selectedMapping, showMappingPreview],
  );

  const shouldShowMappingPreview = useCallback(
    (mapping: IAtlasmapMapping) =>
      showMappingPreview &&
      !!selectedMapping &&
      mapping.id === selectedMapping.id,
    [selectedMapping, showMappingPreview],
  );

  const expressionToolbar = (
    <ExpressionToolbar>
      <ConditionalExpressionInput
        mappingExpression={
          mappingExpressionEnabled ? currentMappingExpression : undefined
        }
        executeFieldSearch={executeFieldSearch}
        getFieldEnums={getFieldEnums}
        mappingExpressionAddField={mappingExpressionAddField}
        mappingExpressionClearText={mappingExpressionClearText}
        isMappingExpressionEmpty={isMappingExpressionEmpty}
        mappingExpressionInit={mappingExpressionInit}
        mappingExpressionInsertText={mappingExpressionInsertText}
        mappingExpressionObservable={mappingExpressionObservable}
        mappingExpressionRemoveField={mappingExpressionRemoveField}
        trailerId={trailerId}
        disabled={!selectedMapping}
        onToggle={handlers.onToggleExpressionMode}
        setSelectedEnumValue={setSelectedEnumValue}
      />
    </ExpressionToolbar>
  );

  const sourceEvents = useMemo<ISourceColumnCallbacks>(
    () => ({
      isSource: true,
      acceptDropType: 'target',
      draggableType: 'source',
      canDrop: (dt, i) => {
        return isFieldDragAndDropAllowed(i.payload as IAtlasmapField, dt);
      },
      onDrop: (s, t) => onCreateMapping(s, t?.payload as IAtlasmapField),
      onShowMappingDetails: selectMapping,
      onAddToSelectedMapping: onAddToMapping,
      canAddToSelectedMapping: canAddToSelectedMapping,
      canAddFieldToSelectedMapping: (f) =>
        isFieldAddableToSelection('source', f),
      onAddFieldToSelectedMapping: onAddToMapping,
      canRemoveFromSelectedMapping: (f) =>
        isFieldRemovableFromSelection('source', f),
      onRemoveFromSelectedMapping: onRemoveFromMapping,
      onCreateConstant: () => handlers.onCreateConstant(constants),
      onEditConstant: (constName, constValue) => {
        const name = constName;
        const value = constValue;
        const valueType = getConstantType(name);

        handlers.onEditConstant({ name, value, valueType }, constants);
      },
      onDeleteConstant: handlers.onDeleteConstant,
      onCreateProperty: (isSource: boolean) => {
        handlers.onCreateProperty(isSource, sourceProperties);
      },
      onEditProperty: (property, scope, isSource) => {
        const [leftPart] = property.split(' ');
        const valueType = getPropertyType(leftPart, scope, isSource);
        handlers.onEditProperty(
          {
            name: leftPart,
            valueType,
            scope,
          },
          true,
          sourceProperties,
        );
      },
      onDeleteProperty: handlers.onDeleteProperty,
      onDeleteDocument: allowDelete
        ? (id) => handlers.onDeleteDocument(id, true)
        : undefined,
      onCaptureDocumentID: (id) => handlers.onCaptureDocumentID(id),
      onChangeDocumentName: (id, name) =>
        handlers.onChangeDocumentName({
          id: id,
          name: name,
          isSource: true,
        }),
      onCustomClassSearch: allowCustomJavaClasses
        ? (isSource: boolean) => handlers.onEnableCustomClass(isSource)
        : undefined,
      onImportDocument: allowImport
        ? (id) => handlers.onImportDocument(id, true)
        : undefined,
      onSearch: searchSources,
      shouldShowMappingPreviewForField,
      onFieldPreviewChange,
      canStartMapping: () => true, // TODO: check that there is at least one target field unmapped and compatible
      onStartMapping: (field) => onCreateMapping(field, undefined),
      onEditCSVParams: (docId, isSource) => {
        handlers.onEditCSVParams(docId, isSource);
      },
      documentExists: documentExists
    }),
    [
      selectMapping,
      onAddToMapping,
      onRemoveFromMapping,
      handlers,
      constants,
      allowDelete,
      allowCustomJavaClasses,
      allowImport,
      searchSources,
      shouldShowMappingPreviewForField,
      onFieldPreviewChange,
      onCreateMapping,
      canAddToSelectedMapping,
      isFieldAddableToSelection,
      isFieldDragAndDropAllowed,
      isFieldRemovableFromSelection,
      sourceProperties,
    ],
  );

  const targetEvents = useMemo<ITargetsColumnCallbacks>(
    () => ({
      isSource: false,
      acceptDropType: 'source',
      draggableType: 'target',
      canDrop: (dt, i) => {
        return isFieldDragAndDropAllowed(i.payload as IAtlasmapField, dt);
      },
      onDrop: (s, t) => onCreateMapping(t?.payload as IAtlasmapField, s),
      canAddToSelectedMapping: canAddToSelectedMapping,
      canAddFieldToSelectedMapping: (f) =>
        isFieldAddableToSelection('target', f),
      onShowMappingDetails: selectMapping,
      onAddToSelectedMapping: onAddToMapping,
      canRemoveFromSelectedMapping: (f) =>
        isFieldRemovableFromSelection('target', f),
      onRemoveFromSelectedMapping: onRemoveFromMapping,
      onCreateProperty: (isSource: boolean) => {
        handlers.onCreateProperty(isSource, targetProperties);
      },
      onEditProperty: (property, scope, isSource) => {
        const [leftPart] = property.split(' ');
        const valueType = getPropertyType(leftPart, scope, isSource);
        handlers.onEditProperty(
          {
            name: leftPart,
            valueType,
            scope,
          },
          false,
          targetProperties,
        );
      },
      onDeleteProperty: handlers.onDeleteProperty,
      onDeleteDocument: allowDelete
        ? (id) => handlers.onDeleteDocument(id, false)
        : undefined,
      onCaptureDocumentID: (id) => handlers.onCaptureDocumentID(id),
      onChangeDocumentName: (id, name) =>
        handlers.onChangeDocumentName({
          id: id,
          name: name,
          isSource: false,
        }),
      onCustomClassSearch: allowCustomJavaClasses
        ? (isSource: boolean) => handlers.onEnableCustomClass(isSource)
        : undefined,
      onImportDocument: allowImport
        ? (id) => handlers.onImportDocument(id, false)
        : undefined,
      onSearch: searchTargets,
      shouldShowMappingPreviewForField,
      onFieldPreviewChange,
      canStartMapping: (field) => !field.isConnected,
      onStartMapping: (field) => onCreateMapping(undefined, field),
      onEditCSVParams: (docId, isSource) => {
        handlers.onEditCSVParams(docId, isSource);
      },
      documentExists: documentExists
    }),
    [
      selectMapping,
      onAddToMapping,
      onRemoveFromMapping,
      handlers,
      allowDelete,
      allowCustomJavaClasses,
      allowImport,
      searchTargets,
      shouldShowMappingPreviewForField,
      onFieldPreviewChange,
      onCreateMapping,
      canAddToSelectedMapping,
      isFieldAddableToSelection,
      isFieldDragAndDropAllowed,
      isFieldRemovableFromSelection,
      targetProperties,
    ],
  );

  const currentView = useMemo(() => {
    switch (activeView) {
      case 'ColumnMapper':
        return (
          <SourceTargetView
            sourceProperties={sourceProperties}
            targetProperties={targetProperties}
            constants={constants}
            sources={sources}
            mappings={mappings}
            targets={targets}
            selectedMappingId={selectedMapping?.id}
            onSelectMapping={selectMapping}
            showMappingPreview={showMappingPreview}
            showTypes={showTypes}
            sourceEvents={sourceEvents}
            targetEvents={targetEvents}
            schemaInfo={templateData}
          />
        );
      case 'MappingTable':
        return (
          <MappingTableView
            mappings={mappings}
            onSelectMapping={selectMapping}
            shouldShowMappingPreview={shouldShowMappingPreview}
            onFieldPreviewChange={onFieldPreviewChange}
          />
        );
      case 'NamespaceTable':
        return (
          <NamespaceTableView
            sources={sources}
            onCreateNamespace={handlers.onCreateNamespace}
            onEditNamespace={(
              docName: string,
              alias: string,
              uri: string,
              locationUri: string,
              targetNamespace: boolean,
            ) =>
              handlers.onEditNamespace(docName, {
                alias,
                uri,
                locationUri,
                targetNamespace,
              })
            }
            onDeleteNamespace={handlers.deleteNamespace}
          />
        );
      default:
        return <>TODO</>;
    }
  }, [
    activeView,
    constants,
    handlers,
    mappings,
    onFieldPreviewChange,
    sourceProperties,
    targetProperties,
    selectMapping,
    selectedMapping,
    shouldShowMappingPreview,
    showMappingPreview,
    showTypes,
    sourceEvents,
    sources,
    targetEvents,
    targets,
    templateData
  ]);

  const renderSidebar = useSidebar({
    onCreateConstant: () => {
      handlers.onCreateConstant(constants, true);
    },
    onCreateProperty: (isSource: boolean) => {
      if (isSource) {
        handlers.onCreateProperty(isSource, sourceProperties, true);
      } else {
        handlers.onCreateProperty(isSource, targetProperties, true);
      }
    },
    onRemoveMapping: handlers.onDeleteSelectedMapping,
    onEditEnum: handlers.onEditMappingEnumeration,
    isEnumMapping: isEnumerationMapping,
  });

  return (
    <>
      {showBanner && <Banner variant="success" isSticky={true} style={{ textAlign: 'center' }}> Template {templateData.templateName} Saved Successfully. </Banner>}
      {showTemplateNotFound && <Banner variant="warning" isSticky={true} style={{ textAlign: 'center' }}> Standard template not found </Banner>}
      <Page
        additionalGroupedContent={
          <PageSection variant={PageSectionVariants.default} padding={{ default: 'noPadding' }}>
            <TemplateSelection
              onResetAtlasmap={handlers.onResetAtlasmap}
              displayData={templateData}
              getCurrentADMArchive={getCurrentADMArchive}
              postSaveProcess={postSaveProcess}
              handleModalToggle={handleModalToggle}
              templateId={templateId}
              hidden={!isValid}
              handlePreviewModal={handlePreviewModal}
              handleLookupConfigModal={handleLookupConfigModal}
              resetToStandard={resetToStandard}
              isTemplateStandard={isTemplateStandard}
              tenantId={tenantId}
              token={token}
              onCloseMappingDetails={deselectMapping}
            />
          </PageSection>
        }
      >
        <PageSection variant="light" padding={{ default: 'noPadding' }}>
          <FieldsDndProvider>
            <CanvasProvider>
              <MainLayout
                loading={pending}
                contextToolbar={isValid ? contextToolbar : <></>}
                expressionToolbar={
                  activeView !== 'NamespaceTable' && expressionToolbar
                }
                showSidebar={!!selectedMapping}
                renderSidebar={isValid ? renderSidebar : () => <></>}
              >
                {isValid ? currentView : <div>No Source and Target Information to Map</div>}
              </MainLayout>
              <FieldDragLayer />
              <AlertGroup isToast>
                {notifications
                  .filter((n) => !n.isRead && !n.mappingId)
                  .slice(0, 5)
                  .map(({ id, variant, title, description }) => (
                    <TimedToast
                      variant={variant}
                      title={title}
                      key={id}
                      onClose={() => markNotificationRead(id)}
                      onTimeout={() => markNotificationRead(id)}
                    >
                      {description}
                    </TimedToast>
                  ))}
              </AlertGroup>
              {dialogs}
            </CanvasProvider>
          </FieldsDndProvider >
        </PageSection>
      </Page>
      {showAuditLog && <AuditLog isModal={showAuditLog} handleModalToggle={handleModalToggle} token={token} />}
      {openPreviewModal && <PreviewModal isModal={openPreviewModal} handleModalToggle={handlePreviewModal} templateData={templateData} token={token} />}
      {openLookupConfigModal && <LookupConfigModal isModal={openLookupConfigModal} handleModalToggle={handleLookupConfigModal} templateId={templateId} templateData={templateData} token={token} postSaveProcess={postSaveProcess}/>}
      {
        showDeleteModal && <Modal
          variant={ModalVariant.small}
          title="Restore Standard Mappings"
          titleIconVariant="warning"
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          actions={[
            <Button key="confirm" variant="primary" onClick={() => { setShowDeleteModal(false); standardReset(true); }}>
              Restore
            </Button>,
            <Button key="cancel" variant="link" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
          ]}
        >
          This Action will remove the current mappings and load the standard template if exists. Do you want to continue?
        </Modal>
      }
    </>
  );
};