import { Button, DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Flex, FlexItem, Label } from '@patternfly/react-core';
import { CogIcon, EraserIcon, EyeIcon, FileCodeIcon, FileIcon, RedoIcon, SaveIcon } from '@patternfly/react-icons';
import React from 'react';
import { saveTemplate } from './FlowService';
import { useToggle } from './utils/useToggle';

export interface ICustomHandlers {
    readonly onResetAtlasmap: () => void;
    readonly postSaveProcess: (data: any) => void;
    readonly displayData: any;
    readonly getCurrentADMArchive: () => Promise<Uint8Array | null>;
    readonly handleModalToggle: () => void;
    readonly handlePreviewModal: () => void;
    readonly handleLookupConfigModal: () => void;
    readonly resetToStandard: () => void;
    readonly onCloseMappingDetails: () => void;
    readonly templateId: string,
    readonly hidden: boolean,
    readonly isTemplateStandard: boolean,
    readonly tenantId: string,
    readonly token: string
}
export function TemplateSelection({ onResetAtlasmap,
    displayData,
    getCurrentADMArchive,
    postSaveProcess,
    handleModalToggle,
    templateId,
    hidden,
    handlePreviewModal,
    handleLookupConfigModal,
    resetToStandard,
    onCloseMappingDetails,
    isTemplateStandard,
    tenantId,
    token }: ICustomHandlers) {
    const { toggleOff } = useToggle(false);
    const runAndClose = (cb: (...args: any[]) => any) => {
        return (...args: any[]) => {
            cb(...args);
            toggleOff();
        };
    };

    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    
    const saveFile = () => {
        return getCurrentADMArchive().then(async (value: Uint8Array | null) => {
            console.log('File data: ', value);
            if (value === null) {
                return;
            }
            const fileContent = new Blob([value], {
                type: 'application/octet-stream'
            });
            const formData = new FormData();
            let queryParam = '';
            // If tenantId is default or empty or undefined or mappingType is CUSTOM, then need to pass templateId in query param i.e create new template
            if(tenantId == emptyGuid || tenantId == '' || tenantId == undefined || displayData?.mappingType == 'CUSTOM'){  
                queryParam = templateId ? `?mappingTemplateId=${templateId}` : '';  
            }
            
            formData.append('mappingTemplateInfo', JSON.stringify({
                'name': displayData?.templateName,
                "sourceId": displayData?.sourceId,
                "sourceName": displayData?.sourceName,
                'sourceEventType': displayData?.sourceEventType,
                "sourceVersion": displayData?.sourceVersion,
                "targetId": displayData?.targetId,
                "targetName": displayData?.targetName,
                "targetVersion": displayData?.targetVersion,
                'targetEventType': displayData?.targetEventType,
                'lookupConfig': displayData.lookupConfig
            }));
            formData.append('mappingTemplateFile', fileContent, displayData?.sourceName);
            return saveTemplate(formData, token, queryParam)
                .then(item => {
                    console.log(item);
                    if (typeof onCloseMappingDetails === 'function') {
                        onCloseMappingDetails();
                    }
                    postSaveProcess(item);
                }).catch(error => {  
                    console.error('An error occurred when saving template ', error);  
                });
        })
    }
    const saveAndPreviewFile = () => {
        saveFile().then(_ => {
            handlePreviewModal();
        })
    }
    const additionalInfo = displayData.customerName ? (<Label color="cyan" icon={<FileCodeIcon />}> CUSTOM - {displayData.customerName} </Label>) : (<Label color="green" icon={<FileIcon />}> STANDARD </Label>);
    return (
        <div>
            <Flex /* flex={{ default: 'flex_2' }} */>
                <Flex direction={{ default: 'column' }} alignSelf={{ default: 'alignSelfStretch' }} flex={{ default: 'flex_4' }}>
                    <FlexItem style={{ margin: 0 }}>
                        <DescriptionList isHorizontal isAutoColumnWidths style={{ /* display: 'flex', */ margin: '10px' }} className='pf-m-fluid'>
                            {displayData?.templateName && <DescriptionListGroup>
                                <DescriptionListTerm style={{ fontSize: 'medium' }}>Template Name: </DescriptionListTerm>
                                <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.templateName}  {additionalInfo}</DescriptionListDescription>
                            </DescriptionListGroup>}
                        </DescriptionList>
                    </FlexItem>
                    <FlexItem style={{ minWidth: '350px' }}>
                        <DescriptionList isHorizontal isAutoColumnWidths style={{ display: 'flex', margin: '10px' }} className='pf-m-fluid'>
                            <Flex>
                                <FlexItem>
                                    {displayData?.sourceName && <DescriptionListGroup>
                                        <DescriptionListTerm style={{ fontSize: 'medium' }}>Source Product: </DescriptionListTerm>
                                        <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.sourceName}</DescriptionListDescription>
                                    </DescriptionListGroup>}
                                </FlexItem>
                                {/* <FlexItem>
                                {displayData?.sourceVersion && <DescriptionListGroup>
                                    <DescriptionListTerm style={{ fontSize: 'medium' }}>Source Version: </DescriptionListTerm>
                                    <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.sourceVersion}</DescriptionListDescription>
                                </DescriptionListGroup>}
                            </FlexItem> */}
                                <FlexItem>
                                    {displayData?.sourceEventType && <DescriptionListGroup>
                                        <DescriptionListTerm style={{ fontSize: 'medium' }}>Source Event Type: </DescriptionListTerm>
                                        <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.sourceEventType}</DescriptionListDescription>
                                    </DescriptionListGroup>}
                                </FlexItem>
                                <FlexItem>
                                    {displayData?.targetName && <DescriptionListGroup>
                                        <DescriptionListTerm style={{ fontSize: 'medium' }}>Target Product: </DescriptionListTerm>
                                        <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.targetName}</DescriptionListDescription>
                                    </DescriptionListGroup>}
                                </FlexItem>
                                {/* <FlexItem>
                                {displayData?.targetVersion && <DescriptionListGroup>
                                    <DescriptionListTerm style={{ fontSize: 'medium' }}>Target Version: </DescriptionListTerm>
                                    <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.targetVersion}</DescriptionListDescription>
                                </DescriptionListGroup>}
                            </FlexItem> */}
                                <FlexItem>
                                    {displayData?.targetEventType && <DescriptionListGroup>
                                        <DescriptionListTerm style={{ fontSize: 'medium' }}>Target Event Type: </DescriptionListTerm>
                                        <DescriptionListDescription style={{ fontSize: 'medium' }}>{displayData?.targetEventType}</DescriptionListDescription>
                                    </DescriptionListGroup>}
                                </FlexItem>
                            </Flex>
                        </DescriptionList>
                    </FlexItem>
                </Flex>
                <FlexItem align={{ default: 'alignRight' }}>
                    {/* <Button style={{ margin: '10px' }} variant="secondary" hidden={isNewMappingTemplate}> <TrashIcon /> Delete </Button> */}
                    <Button style={{ margin: '10px', display: hidden ? 'none' : 'auto' }} variant="secondary" onClick={handleModalToggle}> <FileIcon /> Audit Logs </Button>
                    <Button style={{ margin: '10px', display: hidden ? 'none' : 'auto' }} variant="secondary" onClick={runAndClose(onResetAtlasmap)}> <EraserIcon /> Clear Mappings </Button>
                    <Button style={{ margin: '10px', display: hidden ? 'none' : 'auto' }} variant="secondary" onClick={handleLookupConfigModal}> <CogIcon /> Lookup Config </Button>
                    <Button style={{ margin: '10px', display: hidden || isTemplateStandard ? 'none' : 'auto' }} variant="secondary" onClick={resetToStandard} > <RedoIcon /> Restore Standard Mappings </Button>
                    <Button style={{ margin: '10px', display: hidden ? 'none' : 'auto' }} variant="secondary" onClick={saveAndPreviewFile}> <EyeIcon /> Preview</Button>
                    <Button style={{ margin: '10px', display: hidden ? 'none' : 'auto' }} onClick={saveFile}> <SaveIcon /> Save </Button>
                </FlexItem>
            </Flex>
        </div>
    );
}