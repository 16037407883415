import React, { ReactElement, useCallback } from 'react';

import { useAtlasmap } from '../AtlasmapProvider';
import { useToggle } from '../utils';
import { ExportJsonMappingDialog } from '../../UI';

export function useExportMappingJsonDialog(): [ReactElement, () => void] {
  const { state, toggleOn, toggleOff } = useToggle(false);
  const { exportMappingJsonFile } = useAtlasmap();
  const onExportMappingJsonFile = useCallback(
    (filename: string) => {
      exportMappingJsonFile(filename);
      toggleOff();
    },
    [exportMappingJsonFile, toggleOff],
  );
  const dialog = (
    <ExportJsonMappingDialog
      isOpen={state}
      onCancel={toggleOff}
      onConfirm={onExportMappingJsonFile}
    />
  );
  return [dialog, toggleOn];
}